
























































































































































































































































































































import { Component, Vue } from "vue-property-decorator";
import BasicInputField from "@/components/form-items/BasicInputField.vue";
import OrderPack from "@/views/orders/OrderPack.vue";
import globalAxios from "axios";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { PublicCarts, PublicOrders } from "@/network/api";
import {
  DeliveryType,
  OrderItemAssetAdminListViewModel,
  OrderPackApprovalViewModel,
  OrderPackStatus,
  OrdersRespondToOrderPackOrderPackIdPostRequest,
  OrderCartViewModel,
  OrderStatus,
  VoucherType,
  VoucherApplyToType,
  OrderCartItemViewModel,
} from "@/api-client";
import { Message } from "element-ui";
import SizingDialog from "@/components/orders/order-packs/SizingDialog.vue";
import cloneDeep from "lodash/cloneDeep";

const STATUS_INITIAL = 0,
  STATUS_SAVING = 1,
  STATUS_SUCCESS = 2,
  STATUS_FAILED = 3;

const AppProps = Vue.extend({
  props: {
    id: {
      type: String,
      default: "",
    },
  },
});

@Component({
  name: "OrderPackApproval",
  components: {
    ValidationProvider,
    ValidationObserver,
    BasicInputField,
    OrderPack,
    SizingDialog
  },
})
export default class extends AppProps {
  notesFromCustomer = "";
  loading = false;
  dialogVisible = false
  orderPack: OrderPackApprovalViewModel = {
    id: "",
    orderId: "",
    customerResponseDate: "",
    orderPackStatusId: OrderPackStatus.Generated,
    notesToCustomer: "",
    notesFromCustomer: "",
    noFail: false,
    deliveryTypeId: DeliveryType.Standard,
    expectedDeliveryDate: "",
    approvalDate: "",
    exWorksDate: "",
    requiresCustomerApproval: false,
    orderPack: {
      referenceNumber: "",
      orderPlacementDate: "",
      orderPackNotes: "",
      orderItems: [],
    },
    versionNumber: 1,
    invalidationDate: "",
    invalidationReason: "",
    invalidationAspNetUserId: "",
    invalidationAspNetUserDisplayName: "",
    files: [],
    sizesChanged: false,
    accessKey: ""
  };
  reload = false;
  currentStatus: number = STATUS_INITIAL;
  uploadedFiles: any = [];
  uploadError: any;
  fileCount: any;
  imageFormats: Array<string> = [
    "apng",
    "bmp",
    "jpg",
    "jpeg",
    "jfif",
    "pjpeg",
    "pjp",
    "png",
    "svg",
    "tif",
    "tiff",
    "webp",
    "ico",
    "cur",
  ];
  backupImg = "/img/fallback-file-image-transparent.png";
  messageInstance: any = Message;
  activeTab = 'Approval';
  order: OrderCartViewModel = {
    id: "",
    referenceNumber: "",
    items: [],
    countryId: "",
    isGift: true,
    giftMessage: "",
    deliveryTypeId: DeliveryType.Standard,
    orderStatusId: OrderStatus.NewOrder,
    currencyId: "",
    currencyPricingMultiplyer: 0,
    requiresCustomerApproval: true,
    deliveryAddress: {
      id: "",
      companyName: "",
      addressLine1: "",
      addressLine2: "",
      area: "",
      country: "",
      postalCode: "",
      additionalNotes: "",
      ponumber: ""
    },
    billingAddress: {
      id: "",
      companyName: "",
      addressLine1: "",
      addressLine2: "",
      area: "",
      country: "",
      postalCode: "",
      additionalNotes: "",
      ponumber: ""
    },
    customerContact: {
      id: "",
      name: "",
      email: "",
      phoneNumber: "",
      referenceNumber: "",
      paymentReference: ""
    },
    customerContacts: [],
    orderDocuments: [],
    isQuote: true,
    storeId: "",
    paymentsTotal: 0,
    paymentsConvertedTotal: 0,
    additionalCostsConvertedTotal: 0,
    quoteMessage: "",
    accessKey: "",
    allowedPaymentProviders: [],
    voucher: {
      id: "",
      voucherTypeId: VoucherType.Absolute,
      value: 0,
      redemptionCode: "",
      totalVoucherAmount: null,
      totalAmount: 0,
      convertedTotalAmount: 0,
      minimumCartValue: 0,
      maximumRedemptions: 0,
      startDate: "",
      endDate: "",
      maximumValue: 0,
      voucherApplyToTypeId: VoucherApplyToType.TotalCost,
      productIds: [],
      countryIds: []
    }
  };
  orderItems:OrderCartItemViewModel[] = [];
  initialOrderItems:OrderCartItemViewModel[] | null = null;
  duplicateSizeErrors: string[] = []

  get uploadPath() {
    return `${process.env.VUE_APP_ROOT_API}/files`;
  }

  get isInitial() {
    return this.currentStatus === STATUS_INITIAL;
  }
  get isSaving() {
    return this.currentStatus === STATUS_SAVING;
  }
  get isSuccess() {
    return this.currentStatus === STATUS_SUCCESS;
  }
  get isFailed() {
    return this.currentStatus === STATUS_FAILED;
  }

  get hasUpdatedSizes() {
    if(!this.initialOrderItems) {
      return false
    } else {
      return JSON.stringify(this.initialOrderItems) !== JSON.stringify(this.orderItems);
    }
  }

  created() {
    this.messageInstance.closeAll();
    this.loadInitialData();

    this.$watch('dialogVisible', () => {
      if(!this.dialogVisible) {
        if(this.hasUpdatedSizes && !this.notesFromCustomer) {
          this.notesFromCustomer = 'Sizes changed.'
        } else if(!this.hasUpdatedSizes && this.notesFromCustomer == 'Sizes changed.') {
          this.notesFromCustomer = "";
        }
      }
    })
  }

  setInitialSizeUpdateValue(items:OrderCartItemViewModel[]) {
    if(!this.initialOrderItems) {
      this.initialOrderItems = cloneDeep(items);
    }
  }

  handleSizeUpdateConfirm () {
    this.dialogVisible = false;
  }

  handleSizeUpdateCancel(copy: OrderCartItemViewModel[]) {
    this.dialogVisible = false;
    this.orderItems = cloneDeep(copy);
  }

  fileFormat(file: string) {
    let arr = file.split(".");
    return arr[arr.length - 1];
  }

  fileBgImage(file: string) {
    if (this.imageFormats.includes(this.fileFormat(file))) {
      return `url('${this.uploadPath}/${file}')`;
    } else {
      return `url('${this.backupImg}')`;
    }
  }

  handleTabClick(tab:any) {
    this.activeTab = tab.label;
    if(this.activeTab === "Adjustments Required") {
      const mediaQuery  = window.matchMedia("(min-width: 992px)");
      if(mediaQuery.matches) {
        setTimeout(() => {
          document.getElementById("notes")!.focus();
          document.getElementById("notes")!.scrollIntoView();
        }, 0);
      }
    }
  }

  updateSizes() {
    this.dialogVisible = true;
  }

  customerResponse(response: "accept" | "reject") {
    this.messageInstance.closeAll();
    this.duplicateSizeErrors = [];
    let customerApproval = response === "accept" ? true : false;
    if (!customerApproval && !this.notesFromCustomer) {
      this.$message.error({
        showClose: true,
        duration: 0,
        message: "Please enter a note regarding the changes requested. If you updated sizes and don't require any other changes, simply add 'sizes updated' to your notes.",
      });
      document.getElementById("notes")!.focus();
      return;
    } else {
      this.loading = true;

      const toSend: OrdersRespondToOrderPackOrderPackIdPostRequest = {
        approved: customerApproval,
        files: this.uploadedFiles,
        orderItems: this.orderItems,
        notesFromCustomer: !customerApproval ? this.notesFromCustomer : undefined 
      }

      PublicOrders.ordersRespondToOrderPackOrderPackIdPost(
        this.id,
        toSend
      )
        .then((res) => {
          if (res.data.succeeded) {
            this.$message.success("Your response was sent successfully.");
            this.loadInitialData();
            this.reload = true;
            this.notesFromCustomer = "";
            window.scrollTo({ top: 0, behavior: "smooth" });
          }
        })
        .catch((error) => {
          this.loading = false;
          error.response.data.errors.map((e: any) => {
            this.$message({
              showClose: true,
              type: "error",
              duration: 0,
              message: e.friendlyMessage,
            });
            if(e.friendlyMessage.includes("duplicate sizes")) {
              this.duplicateSizeErrors.push(e.friendlyMessage)
              setTimeout(() => {
                window.scrollTo(0, document.body.scrollHeight);
              }, 0);
            }
            return e.friendlyMessage;
          });
        });
    }
  }

  loadInitialData() {
    if (this.id) {
      this.loading = true;

      PublicOrders.ordersGetOrderPackOrderPackIdGet(this.id)
        .then(async (res) => {
          if (res.data.succeeded) {
            this.orderPack = res.data.resultData as OrderPackApprovalViewModel;

            await this.fetchOrder()
          }
          this.loading = false;
        })
        .catch((error) => {
          debugger;
          this.loading = false;

          let hasProp = Object.prototype.hasOwnProperty.call(error, "message");
          if (
            hasProp &&
            error.message ===
              "Cannot read properties of null (reading 'refreshToken')"
          ) {
            this.$message({
              showClose: true,
              type: "error",
              duration: 0,
              message:
                "Please log in to the relevant account to view this page.",
            });
            this.$router.push(`/login?redirect=${this.$route.path}`);
          } else {
            if (error.response.status == 401) {
              this.$message({
                showClose: true,
                type: "error",
                duration: 0,
                message:
                  "Please log in to the relevant account to view this page.",
              });
              this.$router.push(`/login?redirect=${this.$route.path}`);
            } else {
              error.response.data.errors.map((e: any) => {
                this.$message({
                  showClose: true,
                  type: "error",
                  duration: 0,
                  message: e.friendlyMessage,
                });
                return e.friendlyMessage;
              });
            }
          }
        });
    }
  }

  async fetchOrder() {
    await PublicCarts.cartsGetCartGet(this.orderPack.orderId, this.orderPack.accessKey)
    .then((res) => {
      if (res.data.succeeded) {
        this.order = res.data.resultData as OrderCartViewModel;
        this.orderItems = cloneDeep(res.data.resultData?.items as OrderCartItemViewModel[]);
      }
      this.loading = false;
    })
    .catch((error) => {
      this.loading = false;
      error.response.data.errors.map((e: any) => {
        this.$message({
          showClose: true,
          type: "error",
          duration: 0,
          message: e.friendlyMessage,
        });
        return e.friendlyMessage;
      });
    });
  }

  handleFileName(file: any) {
    let arr = file.split("/");
    return arr[arr.length - 1];
  }

  deleteItem(file: any) {
    let refs = this.$refs as any;
    refs.file.value = "";
    this.uploadedFiles.splice(this.uploadedFiles.indexOf(file), 1);
  }

  async filesChange(fileList: any, asset: OrderItemAssetAdminListViewModel) {
    let files = [...fileList];
    if (!files.length) return;
    await Promise.all(
      files.map(async (file: any) => {
        const formData = new FormData();
        formData.append("file", file);
        let path = (await this.save(formData)) as any;

        if (path) {
          this.uploadedFiles.push(path);
        }
      })
    );
  }

  async save(formData: any) {
    return new Promise<void>((resolve, reject) => {
      // upload data to the server
      this.currentStatus = STATUS_SAVING;

      this.upload(formData)
        .then((x: any) => {
          this.currentStatus = STATUS_INITIAL;
          resolve(x);
        })
        .catch((err: any) => {
          reject(err);
          this.uploadError = err.response;
          this.currentStatus = STATUS_FAILED;
        });
    });
  }

  async upload(formData: any) {
    const url = `${this.uploadPath}`;
    return globalAxios
      .post(url, formData)
      .then((x: any) => x.data)
      .then((img: any) => img.relativePath);
  }

  renderAlert() {
    if (this.orderPack.invalidationDate) {
      return `<b style="font-size: 1.5em; text-transform: uppercase;">** PLEASE NOTE: **</b>
              <p style="margin: 14px 0;">There order pack is no longer valid.</p>
              <p style="margin: 14px 0;"><strong>Reason:</strong> ${this.orderPack.invalidationReason}.</p>`;
    } else if (this.orderPack.orderPackStatusId == "Approved") {
      return `<span style="text-transform: uppercase;">You have approved - no action required.</span>`;
    } else if (this.orderPack.orderPackStatusId == "Rejected") {
      return `<span style="text-transform: uppercase;">We are reviewing your requested changes.</span>`;
    } else if (this.orderPack.orderPackStatusId == "Voided") {
      return `<b style="font-size: 1.5em; text-transform: uppercase;">** PLEASE NOTE: **</b>
              <p style="margin: 14px 0;">There is a more recent order pack for this order, therefore the following has been voided.</p>`;
    } else {
      return `<b style="font-size: 1.5em; text-transform: uppercase;">** ATTENTION NEEDED: YOUR INPUT REQUIRED **</b>
              <p style="margin: 20px 0;"><b>Take a moment to carefully review the entire page.</b></p>
              <ol style="text-align: left; margin-top: 6px;padding-left:0;list-style:none;">
                <li style="margin-bottom:10px;">If everything is in order, simply click on "APPROVE".</li>
                <li style="margin-bottom:10px;">However, if there are any inaccuracies or discrepancies, click on "ADJUSTMENTS REQUIRED".</li>
                <li>Provide specific details and then press "SUBMIT CHANGE REQUEST".</li>
              </ol>`;
    }
  }
}
