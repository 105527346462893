































































































































































































































































import { Component, Vue } from "vue-property-decorator";
import {
  OrderAdminListViewModel,
  OrderStatus,
  OrderAdminListViewModelPaginatedList,
  OrderStatusListViewModel,
} from "@/api-client";
import ElTableDraggable from "@/components/ElTableDraggable.vue";
import Pagination from "@/components/Pagination.vue";
import DialogBox from "@/components/DialogBox.vue";
import ItemsPerPage from "@/components/form-items/ItemsPerPage.vue";
import DateRangeFilter from "@/components/form-items/DateRangeFilter.vue";
import { AdminOrders } from "@/network/api";
import { handleQueries } from "@/utils/handleQueries"
import { formatDate } from "@/utils/formatDate";

const AppProps = Vue.extend({
  props: {
    enquiryId: {
      type: String,
      default: "",
    },
    // loading: {
    //   type: Boolean,
    //   default: false,
    // },
  },
});

@Component({
  name: "QuotesList",
  components: {
    ElTableDraggable,
    Pagination,
    DialogBox,
    ItemsPerPage,
    DateRangeFilter,
  },
  filters: {
    statusFilter: (status: string) => {
      const statusMap: { [key: string]: string } = {
        Cart: "brand",
        Received: "success",
        AwaitingPayment: "danger",
      };
      return statusMap[status];
    },
    paymentFilter: (payment: number) => {
      const paymentMap: { [key: string]: string } = {
        even: "success",
        over: "danger",
        under: "warning",
      };
      let paymentStatus = "";
      if (payment > 0) {
        paymentStatus = "over";
      } else if (payment < 0) {
        paymentStatus = "under";
      } else {
        paymentStatus = "even";
      }
      return paymentMap[paymentStatus];
    },
    parseTime: (timestamp: string) => {
      return new Date(timestamp).toISOString();
    },
  },
})
export default class extends AppProps {
  quotes: OrderAdminListViewModelPaginatedList = {
    hasNextPage: false,
    hasPreviousPage: false,
    items: [],
    pageIndex: 1,
    totalCount: 1,
    totalPages: 1,
    pageSize: 1,
  };
  quoteItems: Array<OrderAdminListViewModel> = [];
  search: string | undefined = this.$route.params.search || "";
  pageNumber = 1;
  itemPerPage = 5;
  // dialogVisible = false;
  // dialogMessage =
  //   "<span><stong>Are you sure you want to delete this order?</strong></span>";
  // confirmText = "Yes";
  // cancelVisible = true;
  selectedItem: OrderAdminListViewModel = {
    id: "",
    referenceNumber: "",
    orderStatusId: OrderStatus.Cart,
    customerEmail: "",
    orderPlacementDate: "",
    expectedDeliveryDate: "",
    convertedOrderTotal: 0,
    factories: [],
    exWorksApproved: false,
    exWorksDate: "",
    groupedUK: false,
    groupedUSA: false,
    currency: {
      id: "",
      name: "",
      code: "",
      symbol: "",
      pricingMultiplier: 1,
      decimalPlaces: 2,
    },
    convertedPaymentOutstanding: 0,
    amendmentFromCustomer: '',
    isQuote: false,
    isStoreOrder: false,
    storeId: null,
    storeName: null,
    disableAutoSendFactoryEmails: false
  };
  debounce: any = null;
  status: Array<OrderStatus> = [];
  selectedPlacementDate: any = "";
  selectedExpectedDeliveryDate: any = "";
  selectedTypeFilter = "";
  orderStatus?: undefined | OrderStatus;
  expectedDelivery = {
    startDate: undefined,
    endDate: undefined,
  };
  orderPlacement = {
    startDate: undefined,
    endDate: undefined,
  };
  showAllText: Array<string> = [];
  loading = false;
  selection: Array<any> = [];
  orderStatuses: Array<OrderStatusListViewModel> = [];

  handleDate(date:any) {
    return formatDate(date)
  }

  created() {
    let toQuery = {
      search: this.search,
      pageNumber: this.pageNumber,
      itemPerPage: this.itemPerPage,
      status: this.status,
      ['orderPlacement.startDate']: this.orderPlacement.startDate,
      ['orderPlacement.endDate']: this.orderPlacement.endDate,
      ['expectedDelivery.startDate']: this.expectedDelivery.startDate,
      ['expectedDelivery.endDate']: this.expectedDelivery.endDate,
    }
    this.setQueryWatch(toQuery)

    this.loadInitialData();

    this.$watch("enquiryId", () => {
      this.loadInitialData();
    })
  }

  sendQuoteReminder(id: string) {
    AdminOrders.adminOrdersSendQuoteReminderOrderIdPost(id)
    .then((res) => {
      if(res.data.succeeded) {
        this.$message({showClose: true, type: 'success', message: 'Checkout reminder sent!'})
      }
    })
    .catch((error) => {
      this.$message({showClose: true, type: 'error', duration: 0, message: 'Error sending checkout reminder.'})
        error.response.data.errors.map((e: any) => {
        this.$message.error(e.friendlyMessage);
        return e.friendlyMessage;
      });
    });
  }

  setQueryWatch(fields: any) {
    let queries = this.$route.query;

    for (const field in fields) {
      if (queries[field]) {
        let object = null as any

        if(field.includes('.')) {
          object = field.split('.')
        }

        if(object?.length == 2) {
          this[object[0]][object[1]] = queries[field];
        } else {
          this[field] = queries[field];
        }
      }

      this.$watch(field, (val) => {
          handleQueries(field, val)
      })
    }
  }

  handleSearch() {
    this.pageNumber = 1;
    this.loadInitialData().then(() => {
      document.getElementById('search')!.focus();
    }) 
  }

  handlePageNumberClick(page: any) {
    this.pageNumber = page;
    this.loadInitialData();
  }

  handleStatusName(id:string) {
    if(this.orderStatuses.length) {
      let match = this.orderStatuses.find((order) => id == order.id)
      if(match) {
        return match.name
      }
    }
    return id;
  }

  copyLinkPath(row:any) {
    let link = `${process.env.VUE_APP_ROOT_WEB}/quote/${row.id}`;
    navigator.clipboard.writeText(`${link}`)
    this.$message.success('Path copied.')
  }

  linkToQuote(row: OrderAdminListViewModel) {
    let link = `${process.env.VUE_APP_ROOT_WEB}/designer/product-selector/all?quote=${row.id}`;
    window.open(link, "_blank");
  }

  newQuote(row: OrderAdminListViewModel) {
    this.$emit("edit", row.id);
  }

  clearOrderStatusFilter() {
    this.pageNumber = 1
    this.status = [];
    // this.$router.replace({ name: "Orders" });
    this.loadInitialData();
  }

  clearAllFilters() {
    this.pageNumber = 1
    this.search = "";
    this.expectedDelivery = {
      startDate: undefined,
      endDate: undefined,
    };
    this.orderPlacement = {
      startDate: undefined,
      endDate: undefined,
    };
    this.clearOrderStatusFilter();
  }

  get areAllSelected() {
    let unselected = 0;
    this.quoteItems.forEach((item) => {
      if (!this.selection.includes(item.id)) {
        unselected += 1;
      }
    });

    return unselected === 0 ? true : false;
  }

  handleSelectAll() {
    if (!this.areAllSelected) {
      this.quoteItems.forEach((order) => {
        if (!this.selection.includes(order.id)) {
          this.selection.push(order.id);
        }
      });
    } else {
      this.quoteItems.forEach((order) => {
        this.selection = this.selection.filter((item) => item !== order.id);
      });
    }

    console.log(this.selection);
  }

  handleSelect(id: any) {
    if (this.selection.includes(id)) {
      this.selection = this.selection.filter((item) => item !== id);
    } else {
      this.selection.push(id);
    }

    //TODO handle what happens here when call available from liam
    console.log(this.selection);
  }

  handleName(name: string) {
    if (name) {
      const parsedName = name.toLowerCase().split(" ").join("-");
      return parsedName;
    }
    return "";
  }

  handleStatus(selected: OrderStatus) {
    if(this.status.includes(selected)) {  
      this.status = this.status.filter(item => {
        return item !== selected
      })
    } else {
      if(typeof this.status == 'string') {
        const arr = [this.status, selected];
        this.status = arr;
      } else {
        this.status.push(selected);
      }
    }

    this.pageNumber = 1
    this.loadInitialData();
  }

  async loadInitialData() {
    this.loading = true;
    
    if(this.enquiryId) {
      await AdminOrders.adminOrdersGet(
      this.pageNumber,
      this.itemPerPage,
      true,
      false,
      this.status,
      this.search || undefined,
      this.orderPlacement.startDate,
      this.orderPlacement.endDate,
      this.expectedDelivery.startDate,
      this.expectedDelivery.endDate,
      this.enquiryId
    )
      .then((res) => {
        if (res.data.succeeded) {
          this.quotes = res.data
            .resultData as OrderAdminListViewModelPaginatedList;
          this.quoteItems = this.quotes.items as Array<OrderAdminListViewModel>;
        }
      })
      .catch((error) => {
        //this.dialogVisible = false;
        this.loading = false;
        error.response.data.errors.map((e: any) => {
          this.$message({showClose: true, type: 'error', duration: 0, message: e.friendlyMessage})
          return e.friendlyMessage;
        });
      });
    }

    await AdminOrders.adminOrdersGetOrderStatusesGet()
    .then((res) => {
        if (res.data.succeeded) {
          this.orderStatuses = res.data
            .resultData as Array<OrderStatusListViewModel>;
        }
      })
      .catch((error) => {
        //this.dialogVisible = false;
        this.loading = false;
        error.response.data.errors.map((e: any) => {
          this.$message({showClose: true, type: 'error', duration: 0, message: e.friendlyMessage})
          return e.friendlyMessage;
        });
      });

    this.loading = false;
  }

  beforeUnmount() {
    clearTimeout(this.debounce);
  }
}
