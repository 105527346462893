





























































































































































import {
  UserAdminCreateModel,
  UserAdminListViewModel,
  UserAdminUpdateModel,
} from "@/api-client";
import { Component, Vue } from "vue-property-decorator";
import DialogBox from "@/components/DialogBox.vue";
import ImageUploader from "@/components/form-items/ImageUploader.vue";
import BasicInputField from "@/components/form-items/BasicInputField.vue";
import BasicSelectorField from "@/components/form-items/BasicSelectorField.vue";
import BasicSwitchField from "@/components/form-items/BasicSwitchField.vue";
import { sortAscending } from "@/utils/sortAscending";
import {
  ValidationProvider,
  ValidationObserver,
  extend,
  configure,
} from "vee-validate";
import { AdminRoles, AdminUsers, Authentication } from "@/network/api";
import BasicPhoneNumberField from "@/components/form-items/BasicPhoneNumberField.vue";

const AppProps = Vue.extend({
  props: {
    id: {default: ''}
  }
})

@Component({
  name: "NewUser",
  components: {
    BasicSelectorField,
    BasicSwitchField,
    DialogBox,
    ValidationProvider,
    ValidationObserver,
    ImageUploader,
    BasicInputField,
    BasicPhoneNumberField
  },
  beforeRouteEnter(to, from, next) {
    next((vm:any) => {
      vm.fromPath = from.name
    })
  }
})
export default class extends AppProps {
  name: string | undefined = "";
  dialogVisible: boolean = false;
  dialogMessage: string = "";
  disabled: boolean = false;
  showBanner: boolean = true;
  user: UserAdminListViewModel = {
    id: "",
    displayName: "",
    email: "",
    roles: [],
    isDefaultBankTransferOrderManager: false,
    isDefaultStripeOrderManager: false,
    isDefaultEnquiryOrderManager: false,
    isDefaultStoreOrderManager: false,
    isDefaultBankTransferSalesManager: false,
    isDefaultEnquirySalesManager: false,
    isDefaultStoreSalesManager: false,
    isDefaultStripeSalesManager: false
  };
  roles: Array<string> = [];
  confirmText: string = "Ok";
  cancelVisible: boolean = true;

  newUser: UserAdminCreateModel | UserAdminUpdateModel = {
    displayName: "",
    email: "",
    phoneNumber: '',
    roles: [],
    isDefaultBankTransferOrderManager: false,
    isDefaultStripeOrderManager: false,
    isDefaultEnquiryOrderManager: false,
    isDefaultStoreOrderManager: false,
    isDefaultBankTransferSalesManager: false,
    isDefaultEnquirySalesManager: false,
    isDefaultStoreSalesManager: false,
    isDefaultStripeSalesManager: false
    // isDefaultSalesManager: false
  };
  loading = false;
  dirtyTimer: any = null
  phoneNumber = {
    code: '',
    number: ''
  };
  debouncePhone:any = null;
  fromPath = ''

  get uploadPath() {
    return `${process.env.VUE_APP_ROOT_API}/files`;
  }

  created() {
    this.loadInitialData()

    this.$watch('phoneNumber', function handler(newValue) {
      clearTimeout(this.debouncePhone)
      this.debouncePhone = setTimeout(() => {
          this.newUser.phoneNumber = `${newValue.code}-${newValue.number}`;
        }, 400)
      },
      {deep: true}
    )

    extend("required", {
      validate(value) {
        return {
          required: true,
          valid: ["", null, undefined].indexOf(value) === -1,
        };
      },
      computesRequired: true,
      message: "The {_field_} field is required.",
    });

    extend("phoneNumber", () => {
      if (this.phoneNumber.number) {
        return true;
      }
      return "The {_field_} field is required.";
    });

    extend("roles", () => {
      if (this.newUser.roles.length > 0) {
        return true;
      }
      return "The {_field_} field is required.";
    });

    configure({
      classes: {
        failed: "validation-error",
        invalid: "validation-error",
        required: "validation-error",
      },
    });
  }

  get sortedRoles() {
    return sortAscending(this.roles, true);
  }

  resendEmailVerification() {
    Authentication.identityAuthenticationResendEmailConfirmationPost(this.newUser.email)
      .then((res) => {
        if (res.data.succeeded) {
          this.$message({showClose: true, type: 'success', message: 'Email verification email sent! (If the user has not verified already)'})
        }
        this.loading = false
      })
      .catch((error) => {
      this.loading = false
      this.dialogVisible = false;
      error.response.data.errors.map((e: any) => {
        this.$message({showClose: true, type: 'error', duration: 0, message: e.friendlyMessage})
        return e.friendlyMessage;
      });
    });
  }

  resendPasswordReset() {
    Authentication.identityAuthenticationForgotPasswordPost(this.newUser.email)
      .then((res) => {
        if (res.data.succeeded) {
          this.$message({showClose: true, type: 'success', message: 'Password reset email sent!'})
        }
        this.loading = false
      })
      .catch((error) => {
      this.loading = false
      this.dialogVisible = false;
      error.response.data.errors.map((e: any) => {
        this.$message({showClose: true, type: 'error', duration: 0, message: e.friendlyMessage})
        return e.friendlyMessage;
      });
    });
  }

  clearDirtyClasses() {
    clearTimeout(this.dirtyTimer)
    this.dirtyTimer = setTimeout(() => {
      let dirty = document.querySelectorAll(".dirty");

      if (dirty.length) {
        for (let item of dirty) {
          item.classList.remove("dirty");
        }
        let clear = document.querySelectorAll(".dirty");
      }
    }, 500);
  }

  beforeBack() {
    let isDirty = document.querySelectorAll(".dirty");
    this.confirmText = "Yes";

    if (isDirty.length > 0) {
      this.dialogMessage =
        "<span>Are you sure you want to leave this page?<br>Any unsaved changes will be lost.</span>";
      this.dialogVisible = true;
    } else {
      this.handleConfirm();
    }
  }

  beforeSave() {
    this.dialogMessage =
      "<span>Are you sure you want to save all changes?</span>";
    this.confirmText = "Save";
    this.dialogVisible = true;
  }

  handleConfirm() {
    if (this.confirmText === "Yes") {
      if(this.fromPath === 'Users') {
        this.$router.back()
      } else {
        this.$router.push({ name: "Users"});
      }
    } else {
      this.submitForm();
    }
  }

  submitForm() {
    this.loading = true

    if (this.user.id) {
      AdminUsers.adminUsersIdPut(this.user.id, this.newUser)
        .then((res) => {
          this.loading = false
          this.$message.success("Saved!");
          this.dialogVisible = false;
          this.loadInitialData()
          .then(() => {
            this.clearDirtyClasses()
          })
        })
        .catch((error) => {
          error.response.data.errors.map((e: any) => {
            this.$message({showClose: true, type: 'error', duration: 0, message: e.friendlyMessage})
            return e.friendlyMessage;
          });
          this.dialogVisible = false;
        });
    } else {
      AdminUsers.adminUsersPost(this.newUser)
        .then((res) => {
          if (res.data.succeeded) {
            this.loading = false
            this.$message.success("Saved!");
            this.dialogVisible = false;
            this.$router.push({ name: "Users" });
          }
        })
        .catch((error) => {
          error.response.data.errors.map((e: any) => {
            this.$message({showClose: true, type: 'error', duration: 0, message: e.friendlyMessage})
            return e.friendlyMessage;
          });
          this.dialogVisible = false;
        });
    }
    this.loading = false
  }

  async loadInitialData() {
    this.loading = true
    
    await AdminRoles.adminRolesGet().then((res) => {
      if (res.data.succeeded) {
        this.roles = res.data.resultData as Array<string>;
      }
    })
    .catch((error) => {
    error.response.data.errors.map((e: any) => {
      this.$message({showClose: true, type: 'error', duration: 0, message: e.friendlyMessage})
      return e.friendlyMessage;
    })
    })

    if (this.id) {
      await AdminUsers.adminUsersIdGet(this.id)
      .then((res) => {
        this.user = res.data.resultData as UserAdminListViewModel;
        this.name = this.user.displayName as string;

        this.newUser = (({ id, ...rest }) => rest)(this.user) as any;   
        
        if(this.newUser.phoneNumber) {
          let array = this.newUser.phoneNumber.split("-");
          if (array.length >= 2) {
            this.phoneNumber.code = array[0];
            array.shift()
            this.phoneNumber.number = array.join('-');
          }
        }
      })
    }
    this.loading = false
  }

  beforeDestroy() {
    clearTimeout(this.dirtyTimer)
  }
}
